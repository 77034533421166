//Main Action Types
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";

//Login Action Types
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_END = "LOGIN_USER_END";

//User Action Types
export const FETCH_USER_PROFILE_REQUEST = "FETCH_USER_PROFILE_REQUEST";
export const FETCH_USER_PROFILE_END = "FETCH_USER_PROFILE_END";

export const FETCH_USER_EMPLOYEE_DATA_REQUEST =
  "FETCH_USER_EMPLOYEE_DATA_REQUEST";
export const FETCH_USER_EMPLOYEE_DATA_END = "FETCH_USER_EMPLOYEE_DATA_END";

export const FETCH_USER_ADDRESS_REQUEST = "FETCH_USER_ADDRESS_REQUEST";
export const FETCH_USER_ADDRESS_END = "FETCH_USER_ADDRESS_END";

//Employee Action Types
export const FETCH_EMPLOYEE_REQUEST = "FETCH_EMPLOYEE_REQUEST";
export const FETCH_EMPLOYEE_END = "FETCH_EMPLOYEE_END";

//Address Action Types
export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_END = "CREATE_ADDRESS_END";

//Convenant Action Types
export const FETCH_COVENANTS_LIST_REQUEST = "FETCH_COVENANTS_LIST_REQUEST";
export const FETCH_COVENANTS_LIST_END = "FETCH_COVENANTS_LIST_END";

//How Know Types Action Types
export const FETCH_HOW_KNOW_TYPES_REQUEST = "FETCH_HOW_KNOW_TYPES_REQUEST";
export const FETCH_HOW_KNOW_TYPES_END = "FETCH_HOW_KNOW_TYPES_END";

//Pacient Action Types
export const CREATE_PATIENT_REQUEST = "CREATE_PATIENT_REQUEST";
export const CREATE_PATIENT_END = "CREATE_PATIENT_END";

export const CREATE_COMPANION_REQUEST = "CREATE_COMPANION_REQUEST";
export const CREATE_COMPANION_END = "CREATE_COMPANION_END";

export const CREATE_ANAMNESE_REQUEST = "CREATE_ANAMNESE_REQUEST";
export const CREATE_ANAMNESE_END = "CREATE_ANAMNESE_END";

export const CREATE_COMPANION_ANAMNESE_REQUEST =
  "CREATE_COMPANION_ANAMNESE_REQUEST";
export const CREATE_COMPANION_ANAMNESE_END = "CREATE_COMPANION_ANAMNESE_END";

export const FETCH_PATIENTS_REQUEST = "FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_END = "FETCH_PATIENTS_END";

export const FETCH_MEDICAL_RECORDS_REQUEST = "FETCH_MEDICAL_RECORDS_REQUEST";
export const FETCH_MEDICAL_RECORDS_END = "FETCH_MEDICAL_RECORDS_END";

export const FETCH_PATIENT_REQUEST = "FETCH_PATIENT_REQUEST";
export const FETCH_PATIENT_END = "FETCH_PATIENT_END";

export const RESET_CREATED_STATES = "RESET_CREATED_STATES";

//Agenda Action Types
export const CREATE_EXAM_REQUEST = "CREATE_EXAM_REQUEST";
export const CREATE_EXAM_END = "CREATE_EXAM_END";

export const FETCH_SCHEDULES_REQUEST = "FETCH_SCHEDULES_REQUEST";
export const FETCH_SCHEDULES_END = "FETCH_SCHEDULES_END";

//Payment Method Action Types
export const FETCH_PAYMENT_METHODS_REQUEST = "FETCH_PAYMENT_METHODS_REQUEST";
export const FETCH_PAYMENT_METHODS_END = "FETCH_PAYMENT_METHODS_END";

//Exams Action Types
export const FETCH_EXAMS_REQUEST = "FETCH_EXAMS_REQUEST";
export const FETCH_EXAMS_END = "FETCH_EXAMS_END";

//Procedures Action Types
export const FETCH_PROCEDURES_REQUEST = "FETCH_PROCEDURES_REQUEST";
export const FETCH_PROCEDURES_END = "FETCH_PROCEDURES_END";

//Upload Action Types
export const UPLOAD_PHOTO_REQUEST = "UPLOAD_PHOTO_REQUEST";
export const UPLOAD_PHOTO_END = "UPLOAD_PHOTO_END";

//Photos Action Types
export const FETCH_PHOTOS_REQUEST = "FETCH_PHOTOS_REQUEST";
export const FETCH_PHOTOS_END = "FETCH_PHOTOS_END";

//Treatments Action Types
export const FETCH_TREATMENT_REQUEST = "FETCH_TREATMENT_REQUEST";
export const FETCH_TREATMENT_END = "FETCH_TREATMENT_END";

export const FETCH_LOADING = "FETCH_LOADING";

export const SELECT_PRONTUARIO_TAB_ID = "SELECT_PRONTUARIO_TAB_ID";
export const SELECT_ANAMNESE_TAB_ID = "SELECT_ANAMNESE_TAB_ID";
export const RESET_TABS_ID = "RESET_TABS_ID";
