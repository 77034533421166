import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  auth: {
    username: process.env.REACT_APP_USER_AUTH,
    password: process.env.REACT_APP_PASS_AUTH,
  },
});

api.interceptors.request.use(
  (config) => {
    const now = new Date();
    localStorage.setItem("@session-time", now);

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
