// import external modules
// import internal(own) modules
import { FETCH_SCHEDULES_END, CREATE_EXAM_END } from '../../types/types';
import { combineReducers } from 'redux';

const initialState = {
    schedules: null,
}

export const agendaState = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_EXAM_END:
            return {
            ...state,              
        };   

        case FETCH_SCHEDULES_END:
            return {
            ...state,  
            schedules : action.schedules            
        }; 
        
        default:
            return state;
    }
 };

export const agendaReducer = combineReducers({
    agendaState
});