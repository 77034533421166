import {
  LOGIN_USER_REQUEST,
  SET_ERROR,
  LOGIN_USER_END,
  FETCH_USER_PROFILE_END,
  FETCH_USER_EMPLOYEE_DATA_END,
  FETCH_USER_ADDRESS_END,
} from '../../types/types';
import api from '../../../services/api';
import { getErrorMessage } from '../../../utility/functions';
import { text } from '../../../services/locale';

export const login = ({ UserName, Password }) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });

    //api.post('/api/LoginUser', { UserName, Password }, config)
    api
      .post('/api/login', { username: UserName, password: Password })
      .then((response) => response.data)
      .then((loggedUser) => {
        try {
          if (loggedUser) {
            //evita que o a quantidade de informacao exceda a capacidade
            //de armazenamento do storage
            loggedUser.photo = '';
            sessionStorage.setItem('user', JSON.stringify(loggedUser));

            dispatch({
              type: LOGIN_USER_END,
              loggedUser: loggedUser,
            });
          } else {
          }
        } catch (err) {
          dispatch({
            type: SET_ERROR,
            error: text('login.browser_cota'),
          });
        }
      })
      .catch((reason) => {
        let message = getErrorMessage(reason);
        dispatch({
          type: SET_ERROR,
          error: message,
        });
      });
  };
};

export const logout = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_USER_REQUEST,
    });

    sessionStorage.removeItem('user');
    dispatch({
      type: LOGIN_USER_END,
      loggedUser: null,
    });

    dispatch({
      type: FETCH_USER_PROFILE_END,
      profile: null,
    });

    dispatch({
      type: FETCH_USER_EMPLOYEE_DATA_END,
      employeeData: null,
    });

    dispatch({
      type: FETCH_USER_ADDRESS_END,
      userAddress: null,
    });
  };
};
