import {  LOGIN_USER_END } from '../../types/types';

const initialState = {
    loggedUser : null,
}

const loginUser = (state = initialState, action) => {
    switch (action.type) {
       case LOGIN_USER_END:
         return {
             ...state,
             loggedUser: action.loggedUser
          };

       default:
          return state;
    }
 };
 
 export default loginUser;
 